@media screen and (max-width: 1024px) {
  .ant-modal {
    width: 80% !important;
  }
  .ant-col-1,
  .ant-col-2,
  .ant-col-3,
  .ant-col-4,
  .ant-col-5,
  .ant-col-6,
  .ant-col-7,
  .ant-col-8,
  .ant-col-9,
  .ant-col-10,
  .ant-col-11,
  .ant-col-12,
  .ant-col-13,
  .ant-col-14,
  .ant-col-15,
  .ant-col-16,
  .ant-col-17,
  .ant-col-18,
  .ant-col-19,
  .ant-col-20,
  .ant-col-21,
  .ant-col-22,
  .ant-col-23,
  .ant-col-24 {
    width: 100%;
    flex: 0 1 auto;
    max-width: 100%;
  }
}
.search-form .ant-form-item-label > label,
.search-form .ant-input,
.search-form,
.ant-modal-header,
.ant-modal-body,
.ant-tree-list,
.ant-table,
.ant-btn,
.ant-empty,
.ant-pagination,
.ant-form-item-explain,
.ant-form-item-extra,
.ant-form-item-label > label,
.ant-select,
.ant-select-item,
.ant-input,
.ant-input-number,
.ant-tabs-nav .ant-tabs-tab,
.ant-picker-input > input,
.ant-picker-dropdown,
.ant-transfer,
.ant-transfer-list-body,
.ant-radio-group,
.ant-radio-wrapper,
.ant-checkbox-wrapper {
  font-size: 0.5rem;
}

.ant-tree-title,
.ant-modal-title {
  font-size: 0.6rem;
}

.ant-switch {
  width: 60px;
  height: 1rem !important;
  line-height: 1rem;
  padding: 0 !important;
  .ant-switch-inner {
    margin-right: 0;
    margin-left: 17px;
  }
}

.ant-switch-checked .ant-switch-inner {
  margin-right: 17px;
  margin-left: 0;
}

.ant-switch-checked {
  .ant-switch-handle::before {
    left: 0 !important;
  }
}

.ant-table-content {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #9a9a9a;
    -webkit-box-shadow: inset 0 0 5px #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #9a9a9a;
    -webkit-box-shadow: inset 0 0 5px #000c17;
  }
  .ant-tag {
    margin-bottom: 5px;
  }
}

.table-link {
  color: #337ab7;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
    color: #23527c;
  }
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

table {
  thead {
    tr {
      th {
        padding: 6px 10px !important;
        font-size: 0.5rem;
        text-align: center !important;
      }
    }
  }
  tr {
    td {
      padding: 6px 10px !important;
      font-size: 0.5rem;
      span {
        button {
          margin: 0.1rem !important;
          height: 1.2rem !important;
          padding: 0.1rem 0.5rem !important;
          font-size: 0.5rem !important;
        }
      }
    }
  }
}

.admin-content {
  .admin-main-date {
    input,
    span {
      font-size: 0.5rem;
    }
    &.input {
      input {
        height: 32px;
      }
    }
    &.select-search{
      display: flex;
      align-items: center;
    }
  }
}

.bf-dropdown {
  .dropdown-handler {
    span {
      font-size: 0.5rem;
    }
  }
}

// 系统用户设置及任务管理 公用样式
.admin-serch-main {
  &::after {
    content: "";
    height: 0;
    display: block;
    clear: both;
  }
  > div {
    float: left;
    margin: 0 1rem 0.5rem 0;
  }
  .serch-main-input {
    width: 7.6rem;
  }
}

// tabel => Show
.tabel-footer-main {
  height: 32px;
  line-height: 32px;
  > span {
    display: inline-block;
  }
}
.tabel-show {
  float: right;
  margin-right: 1rem;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.margin-left10 {
  margin-left: 10px;
}

.margin-right10 {
  margin-right: 10px;
}

.button-right {
  float: right;
  margin-left: 10px;
}

.label-item {
  margin: 0 0.5rem 0.5rem 0;
  display: inline-block;
  font-size: 0.5rem;
  .label-title {
    margin-right: 10px;
  }
}
