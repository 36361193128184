html,
body,
button,
code,
dd,
details,
div,
dl,
dt,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
input,
li,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
* {
  box-sizing: border-box;
}
ul,
li,
ol {
  list-style: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* iphone6 7 8 */
html {
  font-size: 17px;
}
/* iphone 5 */
@media screen and (max-width: 320px) {
  html {
    font-size: 16px;
  }
}
/* iphoneX */
@media screen and (min-width: 375px) and (-webkit-device-pixel-ratio: 3) {
  html {
    font-size: 18px;
  }
}
/* iphone6 7 8 plus */
@media screen and (min-width: 414px) {
  html {
    font-size: 19px;
  }
}
/* ipad */
@media screen and (min-width: 768px) {
  html {
    font-size: 20px;
  }
}
/* ipad pro */
@media screen and (min-width: 1024px) {
  html {
    font-size: 21px;
  }
}
/* pc */
@media screen and (min-width: 1100px) {
  html {
    font-size: 22px;
  }
}

#root {
  min-width: 320px;
}
// loading

.admin-bassic-loading {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 99999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .admin-loading-main {
    margin-left: 10.5rem;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }
}
// scroll ball
.admin-scroll-one {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #000c17;
    -webkit-box-shadow: inset 0 0 5px #1890ff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #000c17;
    -webkit-box-shadow: inset 0 0 5px #fff;
  }
}
.admin-scroll-two {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #9a9a9a;
    -webkit-box-shadow: inset 0 0 5px #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #9a9a9a;
    -webkit-box-shadow: inset 0 0 5px #000c17;
  }
}

// main
.mobile.portrait{
  .admin-main,.admin-outer{
    display: none;
  }
  .admin-portrait{
    display: block;
    text-align: center;
    background-image: url("../img/login_bg.jpg");
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    span{
      display: block;
      width: 100%;
      font-size: 1.2rem;
      color: #fff;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      &::before{
        display: block;
        content: "";
        height: 10rem;
        background-image: url("../img/phone.png");
        background-size: auto 10rem;
        background-repeat: no-repeat;
        background-position: center;
        animation: phoneRoate linear 5s infinite;
        opacity: 0;
      }
    }
  }
}
.admin-portrait{
  display: none;
}
.admin-main {
  position: fixed;
  .pro-form-drawer & {
    position: relative;
  }
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &.on {
    .admin-body {
      .admin-content {
        margin-left: 4rem;
        min-height: calc(100% - 1.2rem);
      }
    }
  }
  .admin-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    .admin-content {
      transition: 0.1s;
      min-height: calc(100% - 4.8rem);
      margin: 4.2rem 0.5rem 0.6rem 10rem;
      position: relative;
    }
  }
}
// .mobile {
//   .admin-main {
//     &.on {
//       .admin-body {
//         .admin-content {
//           margin: 0.6rem 0.5rem;
//           min-height: calc(100% - 1.2rem);
//         }
//       }
//     }
//     .admin-body {
//       .admin-content {
//         min-height: calc(100% - 4.8rem);
//         margin: 4.2rem 0.5rem 0.6rem 10rem;
//       }
//     }
//   }
// }
@keyframes phoneRoate{
  0%{
    opacity: 0;
  }
  20%{
    opacity: 1;
    transform:rotate(0);
  }
  80%{
    opacity: 1;
    transform:rotate(90deg);
  }
  100%{
    opacity: 0;
    transform:rotate(90deg);
  }
}
