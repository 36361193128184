.admin-outer {
  &.on {
    .admin-left-menu {
      //left: 1rem;
      width: 4rem;

      .admin-menu-title {
        .collapse-button {
          align-items: center;
          margin: 1rem;
        }
      }
    }
  }
  .admin-left-menu {
    width: 9.5rem;
    background: #001529;
    transition: 0.1s;
    overflow: hidden;
    z-index: 9;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

    .admin-stretch-btn {
      position: fixed;
      right: 0;
      top: 30%;
      opacity: 0.1;
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
    .admin-menu-title {
      height: 3.6rem;
      line-height: 3.6rem;
      background-color: #000c17;
      .logo {
        display: block;
        margin-left: 24px;
        width: 5.5rem;
        height: 100%;
        background-image: url('../../assets/img/logo.svg');
        background-size: 5rem auto;
        background-repeat: no-repeat;
        background-position: left center;
        filter: brightness(0) invert(1);
        float: left;
      }
      .menu {
        display: block;
        width: auto;
        color: #fff;
        margin-right: 20px;
        float: right;
      }
      .collapse-button {
      }
    }
    .admin-menu-nav {
      height: calc(100% - 3.6rem);
      overflow: hidden;
      overflow-y: auto;
      .ant-menu-root {
        .ant-menu-submenu {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &:last-child {
            border-bottom: 0;
          }
          .ant-menu-submenu-title {
            height: 1.6rem;
            line-height: 1.6rem;
            //padding-left: 10px !important;
            font-size: 0.55rem;
            padding-right: 26px;
            .ant-menu-submenu-arrow {
              right: 10px;
              margin-top: 0.09rem;
            }
          }
          .ant-menu-item {
            height: 1.5rem;
            font-size: 0.5rem;
            padding-left: 34px !important;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
