.admin-login {
  background-image: url("../../assets/img/login_bg.jpg");
  background-size: 100% 100%;
  min-width: 320px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .admin-login-main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    .admin-login-from {
      padding-top: 4.2rem;
      background-image: url("../../assets/img/logo_cover.svg");
      background-size: auto 3rem;
      background-repeat: no-repeat;
      background-position: center top;
      transition: all 0.3s;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      input {
        font-size: 0.6rem;
      }
      .admin-login-table {
        width: 15rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 0.3rem;
        position: relative;
        .title {
          font-size: 1rem;
          color: #333333;
        }
        .login-qrcode {
          text-align: center;
          padding-bottom: 0.6rem;
          p {
            margin-bottom: 0.5rem;
            color: #333333;
            font-size: 0.65rem;
          }
        }
        .ant-form-item {
          height: 55px;
          &.ant-form-item-with-help {
            margin-bottom: 0;
          }
          input {
            width: 13rem;
            height: inherit;
          }
          .ant-form-item-control {
            width: 13rem;
          }
          .admin-login-select {
            width: 6rem;
            float: left;
          }
          .admin-login-submit {
            float: right;
            button {
              font-size: 0.6rem;
            }
          }
          .forgot {
            button {
              width: 6.1rem;
            }
          }
        }
        .exp-pwd-info {
          p {
            font-size: 0.5rem;
            margin: 0;
            color: red;
          }
          padding-bottom: 0.6rem;
        }
        .admin-login-footer {
          height: 24px;
          .admin-login-forgot {
            color: #333333;
            line-height: 24px;
            cursor: pointer;
          }
          .admin-login-switch {
            float: right;
          }
        }
      }
      .info-title {
        padding-top: 0.6rem;
        font-size: 0.6rem;
        color: #333333;
      }
    }
  }
}

.mobile {
  .admin-login {
    .admin-login-main {
      .admin-login-from {
        padding-top: 5.2rem;
        background-position-y: 1rem;
        position: inherit;
        transform: none;
        left: initial;
        top: initial;
        .admin-login-table {
          margin: auto;
        }
        .info-title {
          text-align: center;
        }
      }
    }
  }
}
