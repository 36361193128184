.admin-outer {
  &.on {
    .admin-header {
      //top: -4rem;
      left: 4rem;
    }
  }
  .admin-header {
    transition: 0.1s;
    position: fixed;
    left: 9.5rem;
    top: 0;
    right: 0;
    .admin-header-info {
      background-color: #000c17;
      color: #fff;
      height: 2rem;
      line-height: 2rem;
      .header-marquee-main {
        font-size: 0.5rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        .header-marquee-body {
          width: 10000rem;
          height: 2rem;
          position: relative;
          .header-marquee-content {
            left: 0;
            top: 0;
            line-height: 2rem;
            position: absolute;
            animation: marquee linear infinite;
            span {
              margin-right: 0.3rem;
            }
          }
        }
      }
      .header-search-main,
      .header-user-main {
        background-color: #000c17;
        padding-left: 0.5rem;
        z-index: 9;
        position: relative;
        float: right;
        .header-task-main {
          display: none;
          width: 18rem;
          background-color: #fff;
          border: 1px solid #000c17;
          position: fixed;
          right: 0;
          top: 2.5rem;
          bottom: 1rem;
          &.on {
            display: block;
          }
          .title {
            color: #fff;
            padding: 0 0.5rem;
            line-height: 1.5rem;
            background-color: #000c17;
            margin: 0;
            position: relative;
            .close {
              display: block;
              text-align: center;
              position: absolute;
              padding-left: 0.5rem;
              right: 0.1rem;
              top: 0;
              bottom: 0;
            }
          }
          .ant-tabs-nav-operations {
            span {
              color: #333;
            }
          }
          .task-main-content {
            height: calc(100% - 1.6rem);
            overflow: hidden;
            overflow-y: auto;
            .task-nav {
              span {
                display: inline-block;
                min-width: 0.8rem;
                margin-left: 0.2rem;
                padding: 0 0.2rem;
                background-color: #ed6b75;
                border-radius: 0.3rem;
              }
            }
            .ant-tabs-nav-list {
              padding: 0 0.5rem;
            }
            .ant-tabs-nav {
              margin-bottom: 0;
            }
            .task-main-list {
              height: 100%;
              div {
                display: block;
                margin: 0;
                font-size: 0.5rem;
                line-height: 1.5;
                padding: 0.5rem;
                border-bottom: 0.01rem solid #ccc;
                cursor: pointer;
                overflow: hidden;
                &:last-child {
                  border-bottom: none;
                }
                span {
                  color: #333;
                  &:last-child {
                    padding: 0 0.2rem;
                    background: #f1f1f1;
                    float: right;
                  }
                }
              }
            }
            .task-main-nodata {
              padding: 1rem 0;
            }
          }
        }
      }
      .header-search-main {
        .ant-input-affix-wrapper {
          border: 0;
          padding: 1px 11px;
          input {
            font-size: 0.5rem;
          }
        }
        .ant-input {
          height: inherit;
        }
      }
      .header-user-main {
        font-size: 0.7rem;
        span,
        a {
          margin-right: 0.3rem;
          cursor: pointer;
          color: #fff;
          &.telescopic {
            display: none;
          }
          &.task-on {
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border: 0.5rem solid transparent;
              border-bottom: 0.6rem solid #000c17;
              z-index: 9;
              position: absolute;
              left: 0;
              top: 0.9rem;
            }
          }
        }
      }
    }
    .admin-tap-menu {
      background-color: #fff;
      color: #fff;
      height: 1.6rem;
      .ant-menu-horizontal {
        .ant-menu-item,
        .ant-menu-submenu-title {
          font-size: 0.6rem;
          padding: 0 0 0 10px;
          &:nth-child(2) {
            padding: 0 10px;
          }
          .admin-tap-close {
            display: inline-block;
            margin-left: 8px;
            text-align: center;
            i {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.mobile {
  .admin-outer {
    .admin-header {
      .admin-header-info {
        .header-user-main {
          & > span,
          & > a {
            display: none;
          }
          &.on > span,
          &.on > a {
            display: inline-block;
          }
          span {
            &.telescopic {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
.header-select-merchant.ant-modal {
  width: 15rem !important;
  .ant-select {
    width: 100%;
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: calc(-100% + 10rem);
  }
}
