.admin-content {
  font-size: 0.5rem;

  .ant-table-measure-row {
    display: none;
  }

  .button-right {
    float: right;
    margin-left: 10px;
  }

  .platform-title {
    font-size: 0.6rem;
    padding-bottom: 0.3rem;
  }

  .admin-content-platform {
    font-size: 0.5rem;
    border: 1px solid #f1f1f1;
    border-radius: 0.3rem;
    padding: 0.5rem;
    ul,
    p {
      margin-bottom: 0;
    }
    .platform-main {
      height: 6.6rem;
      overflow: hidden;
      li {
        padding: 0.1rem 0;
        height: 1.2rem;
        cursor: pointer;
        position: relative;
        span {
          position: absolute;
          top: 0;
          &:first-child {
            padding: 0 0.2rem;
            background-color: #f1f1f1;
            left: 0;
          }
          &:last-child {
            right: 0;
          }
        }
        span,
        p {
          line-height: 1rem;
          font-size: 0.5rem;
        }
        p {
          padding: 0 5rem 0 3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .admin-content-om {
    margin-top: 10px;
    font-size: 0.5rem;
    border: 1px solid #f1f1f1;
    border-radius: 0.3rem;
    padding: 0.5rem;
    .maintain-button {
      margin-top: 10px;
    }

    .form-select {
      width: 6rem;
    }

    .game-manufacturers-maintain-list {
      .game-manufacturers-ant-tag {
        white-space: pre;
      }

      .placeholder-tag {
        .ant-tag-has-color {
          opacity: 0;
        }

        .placeholder-tag-elem {
          width: 100px;
          height: 22px;
          margin-right: 0;
          border-right: 0;
          border-left: 0;
          white-space: pre;
          margin-bottom: 5px;
        }
      }

      table {
        tr {
          td {
            padding: 6px 0 !important;
            font-size: 0.5rem;

            .maintain-list-tag {
              width: 100%;
              overflow: hidden;

              .ant-tag {
                width: 100%;
                height: 22px;
                margin-right: 0;
                border-right: 0;
                border-left: 0;
                white-space: pre;
                //text-overflow: ellipsis;
                //white-space: nowrap;
                position: relative;
                //padding-right: 15px;
                .anticon-close {
                  position: absolute;
                  top: 5px;
                  right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .game-manufacturer-management {
    .management-title {
      font-size: 0.5rem;

      button {
        float: right;
        margin-left: 10px;
      }
    }

    //.switchGreen {
    //  background-color: #26c281;
    //}
  }
}
.admin-content-om-modal {
  .ant-checkbox-group-item {
    min-width: 100px;
  }
}
